import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import DOMPurify from "dompurify";


const OurCasesSlider = () => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const [addClass, setaddClass] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setaddClass('z-[10]');
    }, 1000);
  }, []);

  let data = [
    {
      logo: "/images/soro-swap-logo.svg",
      description: `<strong>Soroswap.Finance</strong>, an Automated Market Maker (AMM) that's changing the way assets are traded in the Soroban ecosystem.`,
      sideImage: "/images/soro-swap-side-img.png",
      link: "https://soroswap.finance",
    },
    {
      logo: "/images/soro-swap-logo.svg",
      description: `<strong>Soroswap.Info</strong>, Soroswap Info is a powerful explorer tool designed to provide transparency and insights into the operations of the Soroswap decentralized exchange (DEX). This web application acts as your one-stop shop for delving into the world of liquidity pools, tokens, and transactions that power Soroswap.`,
      sideImage: "/images/soro-swap-info-side-image.png",
      link: "https://info.soroswap.finance",
    },
    {
      logo: "/images/npm-logo.svg",
      description: `<strong>@soroban-react</strong> is a simple yet powerful framework for developing modern Soroban dApps using React. With over 1,000 weekly downloads, <strong>@soroban-react</strong> is widely used within the Soroban/Stellar ecosystem and has engaged in collaborations with the Stellar Development Foundation.`,
      sideImage: "/images/soroban-react-packages.png",
      link: "https://www.npmjs.com/package/@soroban-react/core",
    },
    {
      logo: "/images/mercury-sdk-logo-outlined.svg",
      description: `<strong>Mercury-sdk</strong>  is a comprehensive SDK that empowers developers to seamlessly interact with the <a href='https://mercurydata.app/'>Mercury Indexer.</a>`,
      sideImage: "/images/soroban-react-packages.png",
      link: "https://mercury-sdk.paltalabs.io/",
    }

  ];

//   This function for security and to aviod XXS
  const sanitizeHTML = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  return (
    <div className="relative overflow-hidden pb-[100px]">
      <Swiper
        breakpoints={{
          0: {
            slidesPerView: 1.2,
          },
          500: {
            slidesPerView: 1.3,
          },
        }}
        slidesPerView={1.3}
        spaceBetween={25}
        initialSlide={0}
        rewind={true}
        pagination={{clickable: true }}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        modules={[Navigation, Pagination]}
        className="relative w-full"
      >
        {data.map((ele, index) => {
          return (
            <SwiperSlide
              key={index}
              className="customBG3 relative rounded-xl border border-[#3B3C49] bg-black transition block overflow-hidden p-5"
            >
              <div className="relative flex lg:items-center lg:justify-between flex-col lg:flex-row gap-12 lg:gap-5 z-20">
                <div className="max-w-[400px]">
                  <img src={ele.logo} alt="logo" className="w-[150px]" />
                  <div className="text-main-gray mb-10 mt-4" dangerouslySetInnerHTML={sanitizeHTML(ele.description)}></div>
                  <a href={ele.link} target="_blank" className="bg-main transition hover:bg-main-hover text-black font-extrabold rounded-full px-5 py-2.5">Visit</a>
                </div>
                <img src={ele.sideImage} alt="side cover" className="w-[450px] mx-auto lg:mx-0" />
              </div>
              <img src="/images/stars.svg" alt="stars" className="absolute bottom-0 -right-5" />
            </SwiperSlide>
          );
        })}
      </Swiper>

      {/* left and right arrows */}
      <div className="flex items-center justify-center gap-[100px] z-30 relative">
        <div ref={navigationPrevRef} className="relative flex items-center justify-center cursor-pointer -mt-[17px]"><svg width="16" height="22" viewBox="0 0 20 34" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.6167 3.28333L16.6667 0.333324L3.3882e-05 17L16.6667 33.6667L19.6167 30.7167L5.90004 17L19.6167 3.28333Z" fill="white"/></svg></div>
        <div ref={navigationNextRef} className="relative flex items-center justify-center cursor-pointer z-30 -mt-[17px]"><svg width="16" height="22" viewBox="0 0 20 34" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.383301 30.7167L3.3333 33.6667L20 17L3.3333 0.333344L0.383301 3.28334L14.1 17L0.383301 30.7167Z" fill="white"/></svg></div>
      </div>
    </div>
  );
};

export default OurCasesSlider;