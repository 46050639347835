import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const form = useRef();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    companyName: '',
    message: '',
    service: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    companyName: '',
    service: '',
    message: '',
  });

  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear the error message for the field when the user interacts with it
    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation logic
    let newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = 'Name field is required';
    }
    if (!formData.email.trim()) {
      newErrors.email = 'Email field is required';
    }
    if (!formData.service.trim()) {
      newErrors.service = 'Service field is required';
    }
    if (!formData.message.trim()) {
      newErrors.message = 'Message field is required';
    }

    if (Object.keys(newErrors).length === 0) {
      try {
        // Create an object with the form data
        const emailData = {
          name: formData.name,
          email: formData.email,
          companyName: formData.companyName,
          service: formData.service,
          message: formData.message,
        };

        // Send the data using Email.js
        const response = await emailjs.send(
          'service_s85g4cm',
          'template_sc7lm0d',
          emailData,
          'diJJFKbWstBl6tXxO'
        );

        console.log('Email sent successfully');

        // Clear input fields after successful submission
        setFormData({
          name: '',
          email: '',
          companyName: '',
          message: '',
          service: '',
        });

        // Display success message
        setSuccessMessage('Your message has been sent successfully!');

        // Hide the success message after 5 seconds
        setTimeout(() => {
          setSuccessMessage('');
        }, 5000);

      } catch (error) {
        console.error('Email sending failed:', error);
      }
    } else {
      // Set the validation errors
      setErrors(newErrors);
    }
  };

  return (
    <>
      <div className="relative rounded-xl border border-main border-opacity-30 bg-[#121212] bg-opacity-75 overflow-hidden px-4 py-5">
        <div className="relative z-20">
          <form ref={form} onSubmit={handleSubmit}>
            <div className="flex flex-col gap-10">
              <div>
                <div className="font-grotesk text-[20px] font-bold mb-2.5">First Name</div>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Name"
                  className={`bg-[#121212] bg-opacity-75 border ${
                    errors.name ? 'border-[#FF0101]' : 'border-[#EEEEEE]'
                  } w-full h-[40px] rounded-lg placeholder:text-main-gray outline-none focus:border-[#5557F5] px-3`}
                />
                <span className="flex items-center gap-2 text-[#FF0101] mt-2">
                  {errors.name ? (
                    <><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 22 19" fill="none"><path d="M0 19H22L11 0L0 19ZM12 16H10V14H12V16ZM12 12H10V8H12V12Z" fill="#FF0101" /></svg>{' '}{errors.name}</>
                  ) : null}
                </span>
              </div>

              <div>
                <div className="font-grotesk text-[20px] font-bold mb-2.5">Email</div>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                  className={`bg-[#121212] bg-opacity-75 border ${
                    errors.email ? 'border-[#FF0101]' : 'border-[#EEEEEE]'
                  } w-full h-[40px] rounded-lg placeholder:text-main-gray outline-none focus:border-[#5557F5] px-3`}
                />
                <span className="flex items-center gap-2 text-[#FF0101] mt-2">
                  {errors.email ? (
                    <><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 22 19" fill="none"><path d="M0 19H22L11 0L0 19ZM12 16H10V14H12V16ZM12 12H10V8H12V12Z" fill="#FF0101" /></svg>{' '}{errors.email}</>
                  ) : null}
                </span>
              </div>

              <div>
                <div className="font-grotesk text-[20px] font-bold mb-2.5">Company name (optional)</div>
                <input
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  placeholder="Company name"
                  className={`bg-[#121212] bg-opacity-75 border ${
                    errors.companyName ? 'border-[#EEEEEE]' : 'border-[#EEEEEE]'
                  } w-full h-[40px] rounded-lg placeholder:text-main-gray outline-none focus:border-[#5557F5] px-3`}
                />
              </div>

              <div>
                <div className="font-grotesk text-[20px] font-bold mb-2.5">Type of service you're looking for?</div>
                <div className="relative">
                  <select
                    name="service"
                    value={formData.service}
                    onChange={handleChange}
                    className={`bg-[#121212] bg-opacity-75 border cursor-pointer ${
                      errors.service ? 'border-[#FF0101]' : 'border-[#EEEEEE]'
                    } w-full h-[40px] rounded-lg placeholder:text-main-gray outline-none focus:border-[#5557F5] px-3`}
                  >
                    <option value="">Select a service</option>
                    <option value="Defi protocols">Defi protocols</option>
                    <option value="dApp Creation">dApp Creation</option>
                    <option value="Blockchain consulting">Blockchain consulting</option>
                  </select>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-main-gray absolute top-1/2 right-3 transform -translate-y-1/2 pointer-events-none" width="21" height="13" viewBox="0 0 21 13" fill="none"><path d="M20.3987 2.1394L10.3987 12.1394L0.398682 2.1394L2.17368 0.364404L10.3987 8.5894L18.6237 0.364404L20.3987 2.1394Z" fill="white"/></svg>
                </div>
                <span className="flex items-center gap-2 text-[#FF0101] mt-2">
                  {errors.service ? (
                    <><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 22 19" fill="none"><path d="M0 19H22L11 0L0 19ZM12 16H10V14H12V16ZM12 12H10V8H12V12Z" fill="#FF0101" /></svg>{' '}{errors.service}</>
                  ) : null}
                </span>
              </div>

              <div>
                <div className="font-grotesk text-[20px] font-bold mb-2.5">Message</div>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Write your message"
                  className={`bg-[#121212] bg-opacity-75 border ${
                    errors.message ? 'border-[#FF0101]' : 'border-[#EEEEEE]'
                  } w-full rounded-lg placeholder:text-main-gray outline-none focus:border-[#5557F5] resize-none h-[200px] p-3`}
                ></textarea>
                <span className="flex items-center gap-2 text-[#FF0101]">
                  {errors.message ? (
                    <><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 22 19" fill="none"><path d="M0 19H22L11 0L0 19ZM12 16H10V14H12V16ZM12 12H10V8H12V12Z" fill="#FF0101" /></svg>{' '}{errors.message}</>
                  ) : null}
                </span>
              </div>

              {successMessage && (
                <div className="text-main text-lg font-semibold -mt-6">{successMessage}</div>
              )}

              <div className="flex items-center justify-end mb-3 -mt-3">
                <button className="bg-main transition hover:bg-main-hover text-black font-extrabold rounded-full px-5 py-2.5" type="submit">
                  Contact Us
                </button>
              </div>
            </div>
          </form>
        </div>

        <img src="/images/stars2.svg" alt="stars" draggable={false} className="absolute bottom-0 left-0 w-full h-[110px] object-cover object-top" />
      </div>
    </>
  );
};

export default ContactForm;
