import { useState } from 'react';

const Header = () => {

  const [navBar, setnavBar] = useState("translate-x-full");

  return (
    <>
    <div className="relative z-50 w-full transition bg-transparent py-6">
    <div className="mainCont flex items-center justify-between gap-5">
      <a href="#" className="flex w-fit"><img src="/images/logo.svg" alt="logo" className="w-[170px]" /></a>
      <div className={`flex items-start lg2:items-center flex-col lg2:flex-row text-[15px] gap-7 fixed top-0 right-0 lg2:relative lg2:top-auto lg2:left-auto px-4 pt-16 pb-5 lg2:px-0 lg2:pb-0 lg2:pt-0 bg-black lg2:bg-transparent h-screen lg2:h-auto max-h-screen overflow-y-auto border-l-none sm2:border-l-[3px] border-main lg2:border-none w-full sm2:w-[300px] lg2:w-auto z-[9999999] transition lg2:transition-none lg2:translate-x-0 ${navBar}`}>
        <a href="#" onClick={()=> {setnavBar("translate-x-full")}} className="text-white transition hover:text-main font-[500]">Home</a>
        <a href="#services" onClick={()=> {setnavBar("translate-x-full")}} className="text-white transition hover:text-main font-[500]">Services</a>
        <a href="#portfolio" onClick={()=> {setnavBar("translate-x-full")}} className="text-white transition hover:text-main font-[500]">Portfolio</a>
        <a href="#about-us" onClick={()=> {setnavBar("translate-x-full")}} className="text-white transition hover:text-main font-[500]">About Us</a>
        {/* <a href="#how-we-work" onClick={()=> {setnavBar("translate-x-full")}} className="text-white transition hover:text-main font-[500]">How We Work</a> */}
        {/* <a href="#testimonials" onClick={()=> {setnavBar("translate-x-full")}} className="text-white transition hover:text-main font-[500]">Testimonials</a> */}
        <a href="#contact-us" onClick={()=> {setnavBar("translate-x-full")}} className="bg-main transition hover:bg-main-hover text-black font-extrabold rounded-full px-5 py-2">Contact Us</a>
        <button onClick={()=> {setnavBar("translate-x-full")}} className="flex lg2:hidden items-center justify-center absolute top-3 right-3"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#24D906" viewBox="0 0 16 16"><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/></svg></button>
      </div>
      <button onClick={()=> {setnavBar("translate-x-0")}} className="flex items-center justify-center lg2:hidden"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#24D906" viewBox="0 0 16 16"><path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/></svg></button>
    </div>
    <div onClick={()=> {setnavBar("translate-x-full")}} className={`flex lg2:hidden fixed top-0 left-0 min-h-screen min-w-full bg-white bg-opacity-30 transition z-[60] ${navBar === "translate-x-full" ? "opacity-0 invisible" : "opacity-100 visible"}`}></div>
    </div>
    </>
  )
}

export default Header